<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div
          v-if="buttonItems"
          class="ml-1 mr-1 mb-3 row justify-content-between"
        >
          <div class="mb-2" v-for="(group, index) in buttonItems" :key="index">
            <BaseButton
              v-for="(item, index) in group"
              :key="index"
              class="mr-2"
              :variant="item.variant"
              :icon="item.icon"
              :columnInfo="item.columnInfo"
              :disabled="item.disabled"
              @click="item.onClickCallback"
            />
          </div>
        </div>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="packUpInfoList"
          :inputComponents="inputComponents"
        ></BaseTabGroup>
        <section data-class="work-list-table">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="packUpInfoList"
            :inputComponents="inputComponentDetails"
          ></BaseTabGroup>
          <!-- テーブル -->
          <BaseTable
            selectMode="single"
            :fields="fields"
            :items="tableItems"
            @row-selected="onRowSelected"
          />
        </section>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- クリアボタン -->
        <BaseButton
          variant="primary"
          :columnInfo="resultControlInfo.clear"
          @click="clear(obj)"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- 車両マスタモーダル -->
    <VehicleModal
      id="vehicle-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetVehicle"
      @after-close-unset="afterCloseUnsetVehicle"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="planning-orderPackup-register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
    <!-- 解除モーダル -->
    <ConfirmModal
      id="planning-orderPackup-cancel-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="packupCancel(obj)"
    />
    <!-- 情報モーダル -->
    <!-- <InfoModal
      id="planning-orderPackup-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back"
    /> -->
  </div>
</template>
<script>
import packUpInfo from './OrderPackUpInfo'
import { openSubModal } from '@/common/Common.js'
import {
  init,
  deleteRow, //行削除
  packupCancel, //配送纏め解除
  goPackupCancel, //配送纏め解除
  clear,
  //back,
  goRegister,
  register,
} from './PlanningOrderPackUpModal.js'
export default {
  components: {
    VehicleModal: () => import('@/master/vehicle/VehicleModal.vue'),
  },

  data() {
    return {
      tableItems: [], //CurrentList
      selectedItem: [],
      packUpInfoList: new packUpInfo(), //SearchList
      displayData: {},
      obj: this,
      registerMessage: null,
      packupDisabled: true,
      rowDeleteDisabled: true,
      registDisabled: true,
      oriData: new packUpInfo(),
    }
  },

  props: {
    selectedPlanningOrderIds: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    selectedPlanningOrderIds() {
      init(this)
    },
  },

  computed: {
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLANNING_ORDER_PACKUP_MODAL
      )('result_ctrl_info')
    },
    detailInfo() {
      let info = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLANNING_ORDER_PACKUP_MODAL
      )('detail_info')
      info.departure_begin_date.visibility = this.VISIBILITY.DISABLE
      info.arrival_begin_date.visibility = this.VISIBILITY.DISABLE
      info.block_code.visibility = this.VISIBILITY.DISABLE
      info.planning_group_name.visibility = this.VISIBILITY.DISABLE
      info.assign_vehicle_code.visibility = this.VISIBILITY.DISABLE
      info.assign_vehicle_name.visibility = this.VISIBILITY.DISABLE
      info.vehicle_category_name.visibility = this.VISIBILITY.DISABLE
      info.vehicle_model_name.visibility = this.VISIBILITY.DISABLE
      info.weight.visibility = this.VISIBILITY.DISABLE
      info.volume.visibility = this.VISIBILITY.DISABLE
      return info
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLANNING_ORDER_PACKUP_MODAL
      )(this.DOMAIN_NAME.FIELDS)
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */ infoMessage() {
      return this.$store.getters['init/getMessage']('YZ00MG995I', '登録')
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 行削除ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.row_delete,
            disabled: this.rowDeleteDisabledCheck(),
            onClickCallback: () => {
              deleteRow(this)
            },
          },
          // 配送纏め解除ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.order_packup_cancel,
            disabled: this.packupDisabled,
            onClickCallback: () => {
              goPackupCancel(this)
            },
          },
          // 登録ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.register,
            disabled: this.registDisabled,
            onClickCallback: () => {
              goRegister(this)
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          // 場所タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 荷積予定日
              type: 'date',
              id: 'departureBeginDate',
              columnInfo: this.detailInfo.departure_begin_date,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 荷卸予定日
              type: 'date',
              id: 'arrivalBeginDate',
              columnInfo: this.detailInfo.arrival_begin_date,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // ブロックコード
              type: 'text',
              id: 'blockCode',
              columnInfo: this.detailInfo.block_code,
            },
            {
              // 配車計画グループ
              type: 'text',
              id: 'planningGroupName',
              columnInfo: this.detailInfo.planning_group_name,
            },
            {
              // 車両ID
              type: 'text',
              id: 'assignVehicleId',
              columnInfo: this.detailInfo.assign_vehicle_id,
            },
            {
              // 指定車両
              type: 'textGroup',
              id1: 'assignVehicleCode',
              id2: 'assignVehicleName',
              id3: 'vehicleCategoryName',
              id4: 'vehicleModelName',
              columnInfo1: this.detailInfo.assign_vehicle_code,
              columnInfo2: this.detailInfo.assign_vehicle_name,
              columnInfo3: this.detailInfo.vehicle_category_name,
              columnInfo4: this.detailInfo.vehicle_model_name,
              btnColumnInfo: this.detailInfo.sub_modal_vehicle,
              rules: [this.INPUT_TYPE_CHECK.ZIPNUMERIC],
              onClickCallback: () => {
                openSubModal(this, 'vehicle-modal')
              },
            },
          ],
        },
      ]
    },

    inputComponentDetails() {
      return [
        {
          label: this.detailInfo.tab2.label,
          inputComponents: [
            {
              // 容量
              type: 'text',
              id: 'volume',
              columnInfo: this.detailInfo.volume,
            },
            {
              // 質量
              type: 'text',
              id: 'weight',
              columnInfo: this.detailInfo.weight,
            },
          ],
        },
      ]
    },
  },

  methods: {
    clear,
    register,
    packupCancel, //配送纏め解除
    //back,

    /**
     * 車両マスタ参照から取得データ設定
     */
    afterCloseSetVehicle(vehicleInfo) {
      this.packUpInfoList.assignVehicleId = vehicleInfo.vehicle_id
      this.packUpInfoList.assignVehicleCode = vehicleInfo.vehicle_code
      this.packUpInfoList.assignVehicleName = vehicleInfo.vehicle_name
      this.packUpInfoList.vehicleCategoryName =
        vehicleInfo.vehicle_category_name
      this.packUpInfoList.vehicleModelName = vehicleInfo.vehicle_model_name
    },
    /**
     * 車両情報解除
     */
    afterCloseUnsetVehicle() {
      this.packUpInfoList.assignVehicleId = null
      this.packUpInfoList.assignVehicleCode = null
      this.packUpInfoList.assignVehicleName = null
      this.packUpInfoList.vehicleCategoryName = null
      this.packUpInfoList.vehicleModelName = null
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    /**
     * 行削除ボタン
     */
    rowDeleteDisabledCheck() {
      var result = this.rowDeleteDisabled
      if (!result) {
        result = !this.oneSelected()
      }
      return result
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    oneSelected() {
      return this.selectedItem.length === 1
    },
  },
}
</script>
